<template>
  <div class="talentPool">
    <heads></heads>
    <div class="con_wrap">
      <div class="con_box">
        <div class="con_left">
          <div class="nice-scroll" v-html="articleh.content"></div>
        </div>
        <div class="con_right">
          <form class="right_top">
            <img @click="getvalue" src="../../assets/images/search.png" alt="" />
            <input type="search" placeholder="检索：请输入姓名或身份证号或证书编码" v-model="search" />
          </form>

          <ul class="right_btm">
            <li v-for="(items, indexs) in trainPersons" :key="indexs">
              <span class="xingming">{{ items.name }}</span>
              <span class="danwei">{{ items.unit }}</span>
              <span class="shenfengzheng">{{ structure(items.card) }}</span>
              <span class="shenfengzheng">{{ items.certificate_name }}</span>
              <span class="shenfengzheng">{{ items.certificate_no }}</span>
              <span class="danwei">{{ items.train_time }}</span>
            </li>
          </ul>
          <el-pagination background layout="prev, pager, next" :total="page * 10" class="pag"
            @current-change="handleCurrentChange" :current-page.sync="vall">
          </el-pagination>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import { scroll } from "@utils/scroll";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import {
  getTrainPersons,
  getArticle,
  getSearchTrainPersons,
} from "@api/public";

export default {
  components: {
    heads,
    foot,
  },
  data() {
    return {
      page: 0,
      trainPersons: [],
      articleh: [],
      vall: 1,
      search: "",
    };
  },
  mounted: function () {
    let that = this;
    this.handleCurrentChange(1);
    getArticle({ id: 9 }).then(function (res) {
      that.$set(that, "articleh", res.data);
    });
    window.onresize = function () {
      return (function () {
        that.$router.go(0);
      })();
    };
    scroll($(".nice-scroll"));
  },
  methods: {
    handleCurrentChange(val) {
      this.vall = val;
      if (this.search == "") {
        this.getTrainPersons(val);
      } else {
        this.getvalue();
      }
    },
    getTrainPersons(currentVall) {
      let that = this;
      getTrainPersons({ page: currentVall }).then(function (res) {
        that.$set(that, "trainPersons", res.data.datas);
        that.$set(that, "page", res.data.pages);
      });
    },

    getvalue() {
      let that = this;
      if (this.search) {
        getSearchTrainPersons({ name: this.search, page: this.vall }).then(
          function (res) {
            that.$set(that, "trainPersons", res.data.datas);
            that.$set(that, "page", res.data.pages);
          }
        );
      } else {
        this.vall = 1;
        this.handleCurrentChange(this.vall);
      }
    },

    structure(array) {
      let arrBox = [...array];
      // 2.将数组中的4-7位变成*
      var str = "";
      //map() 方法返回一个新数组,数组中的元素为原始数组元素调用函数处理后的值
      arrBox.map((res, index) => {
        if (index > 3 && index < 14) {
          str += "*";
        } else {
          str += res;
        }
      });
      return str;
    },
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style>
.talentPool>.con_wrap {
  width: 100%;
  height: 1352px;
  background: url(../../assets/images/rencaiku.jpg);
  background-size: cover;
  background-position: center 0;
}

.talentPool .con_box {
  width: 1310px;
  height: 644px;
  margin: 0 auto;
  padding-top: 478px;
  display: flex;
}

.con_left {
  width: 418px;
  height: 640px;
  padding: 70px 30px 30px;
  box-sizing: border-box;
}

.con_left>div {
  width: 100%;
  height: 100%;
}

.con_left>div::-webkit-scrollbar {
  width: 0;
}

.con_right {
  width: 834px;
  height: 640px;
  padding-left: 50px;
}

.right_top {
  display: flex;
  align-items: center;
  padding-top: 3px;
}

.right_top>input {
  width: 100%;
  height: 35px;
  box-sizing: border-box;
  background: transparent;
  padding-right: 20px;
  border: 0;
}

.right_top>img {
  width: 24px;
  height: 24px;
  padding: 0 10px;
  cursor: pointer;
}

.right_btm {
  width: 823px;
  height: 423px;
  margin-left: 5px;
  margin-top: 172px;
  /* display: none; */
}

/* .con_right>.right_btm:nth-child(2){
  display: block;
} */
.con_right>.add {
  display: block;
}

.right_btm>li {
  height: 50px;
  display: flex;
  align-items: center;
}

.right_btm>li>span {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
}

.right_btm>li:nth-child(1) {
  border-top: 1px solid #000;
}

.xingming {
  width: 110px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  *zoom: 1;
}

.danwei {
  width: 110px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}

.shenfengzheng {
  width: 170px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  font-size: 12px;
  line-height: 1.5;
}

.pag {
  margin-top: 10px;
  text-align: center;
}
</style>